@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;700&display=swap');

.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}
@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera*/
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
@media (max-width: 442px) {
  .grid-cols-1-xs {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /*IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-slideshow-container .default-nav {
  background: rgba(255, 255, 255, 0) !important;
  color: #fff !important;
}

.cartHide {
  animation: cartHide 0.2s forwards;
  animation-timing-function: cubic-bezier(0.08, 0.54, 0.72, 1);
}

.cartShow {
  animation: cartShow 0.2s forwards;
  background-color: white;
  animation-timing-function: cubic-bezier(0.08, 0.54, 0.72, 1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container-snap::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container-snap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

source-css-scss .menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}

body {
  position: relative;
}
.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.active1 {
  background-color: rgba(0, 0, 0, 0.1);
}
