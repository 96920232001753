.react-slideshow-container .default-nav svg {
    fill: yellow;
    width:12px;
}
@media (min-width: 640px) {
    .react-slideshow-container .default-nav svg {
        width:auto;
    }
}

.to-black {
    --tw-gradient-to: rgba(0, 0, 0, 0.281);
}

/* .react-slideshow-container .default-nav:first-of-type {
    margin-right: -50px !important;
} */

.react-slideshow-container .default-nav:last-of-type {
    margin-left: -50px !important;
}