.react-slideshow-container .default-nav svg path{
    fill: white;
}

.react-slideshow-container .container{
    background:red;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.to-black {
    --tw-gradient-to: #fff;
}

.from-transparent {
    --tw-gradient-from: none;
}